<template>
    <div id="app">
        <div class="box-item2">
            <nut-row>
                <nut-col style="width: 100%; height:100%">
                    <div v-if="devicecount == 0">
                        <div style="margin-top: 10%" align="center">
                            <img src="img/newlogo.png" width="100" height="98">
                        </div>
                        <br/>
                        <div class="css-text-center">添加设备，开启智慧养老之旅</div>
                        <div style="margin-top: 85%" align="center">扫描产品包装盒上的二维码即可添加设备</div>
                        <br/>
                        <div style="margin-top: 1%" class="submit" @click="getSign()">扫码添加设备</div>
                        <br/>
                    </div>
                    <div v-else>
                        <li v-bind:key="item.deviceId" v-for="item in userDevice" style="font-size: medium">
                            <div class="box_92">
                            <div style="width:15%; float:left;margin-left:0%">
                                <img v-if="item.deviceName=='人体传感器'" src="img/motion_sensor.png" width="45">
                                <img v-else-if="item.deviceName=='门窗传感器'" src="img/door_window_sensor.png" width="45">
                                <img v-else-if="item.deviceName=='网关'" src="img/gateway.png" width="45">
                                <img v-else-if="item.deviceName=='摄像头'" src="img/monitor.png" width="45">
                                <img v-else-if="item.deviceName=='手环'" src="img/wristband.png" width="45">
                                <img v-else src="img/oldlogo.png" width="45">
                            </div>
                            <div style="width:68%; float:left; margin-left:0%;line-height:1.5rem;">
                                <span style="font-size:1rem;">{{item.deviceName}}（id：{{item.deviceId}}）</span>
                            </div>
                            <div style="width:68%; margin-left:15%;line-height:1.5rem;color:#808080;"><span
                                    style="font-size:0.8rem;">{{item.scene}}</span>
                            </div>
                            </div>
                        </li>
                        <!--<nut-cell>
                            <li v-bind:key="item.deviceId" v-for="item in userDevice" style="font-size: medium">{{item.deviceId}}
                            </li>
                        </nut-cell>-->
                    </div>
                    <!--<div style="margin-top: 10%" align="center"><img src="img/newlogo.png" width="100" height="98"></div>
                    <br/>
                    <div v-if="devicecount == 0" class="css-text-center">添加设备，开启智慧养老之旅</div>
                    <div v-else class="css-text-center">您已添加设备，欢迎使用！</div>
                    <div v-if="devicecount == 0" style="margin-top: 85%" align="center">扫描产品包装盒上的二维码即可添加设备</div>
                    <div v-else style="margin-top: 100%" align="center"></div>
                    <br/>
                    <div v-if="devicecount == 0" style="margin-top: 1%" class="submit" @click="getSign()">扫码添加设备</div>
                    <div v-else style="margin-top: 1%" ></div>
                    <br/>-->
                </nut-col>
            </nut-row>
        </div>
    </div>
</template>

<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"> </script>
<script src="http://libs.baidu.com/jquery/2.0.0/jquery.js"></script>

<script type="text/javascript">
    import {
        bindDevice,
        countUserDevice,
        getMyoldman,
        getSign, getUserDevice,
        getWXOpenId
    } from "../api/getMyoldman";
    import wx from 'weixin-js-sdk';
    import $ from "jquery";

    var oldman , openId

    export default {
        //inject: ['reload'],   //注入依赖（在App.vue中），实现页面刷新
        name: "Device",
        data(){
            return{
                myoldman: [{}],
                oldman: [{}],
                oldman: null,
                devicecount:[{}],
                devicecount:null,
                userDevice: [],
            }
        },
        created() {
            getMyoldman(sessionStorage.getItem('openId')).then(res => {
                this.myoldman = res.data.data
                oldman=this.myoldman[sessionStorage.getItem("ind")?sessionStorage.getItem("ind"):0]
                sessionStorage.setItem("userId", oldman.userId)
                //window.ts.Toast.text("userid:"+oldman.userId);
                //获取老人是否已添加设备（判断是否有网关）
                countUserDevice(oldman.userId).then(res2 => {
                    if (res2.data.code == 200) {
                        this.devicecount = res2.data.data
                        getUserDevice(oldman.userId).then(res => {
                            this.userDevice = res.data.data
                            //window.ts.Toast.text("device:"+this.userDevice[0].deviceId)
                        })
                        //window.ts.Toast.text("devicecount:"+devicecount)
                    } else {
                        window.ts.Toast.text(res2.data.message)
                    }
                })
            })

        },
        methods: {
            getSign() {
                getSign("http://www.laolaizhizhu.com/").then(res => {
                    wxConfig(res.data.appId, res.data.timestamp, res.data.nonceStr, res.data.signature);
                })
            },
            /*countUserDevice(){
                countUserDevice(oldman.userId).then(res => {
                    if (res.data.code == 200) {
                        device = res.data.data
                        window.ts.Toast.text("device_count:"+device)
                    } else {
                        window.ts.Toast.text(res.data.message)
                    }
                })
            }*/

        }
    }
    function wxConfig(_appId,_timestamp, _nonceStr, _signature) {
        debugger;
        console.log('获取数据：' + _timestamp +'\n' + _nonceStr +'\n' + _signature);
        wx.config({
            debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: _appId,// 必填，公众号的唯一标识
            timestamp: _timestamp,// 必填，生成签名的时间戳
            nonceStr: _nonceStr,// 必填，生成签名的随机串
            signature: _signature,// 必填，签名，见附录1
            jsApiList: ['scanQRCode']
            // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.ready(function(){
            wx.scanQRCode({
                desc: 'scanQRCode desc',
                needResult : 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType : [ "qrCode" ], // 可以指定扫二维码还是一维码，默认二者都有
                success : function(res) {
                    console.log("调用扫描成功",res);
                    var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    //$("#codeValue").val(result);//显示结果
                    //alert("扫码结果为：" + result);
                    //window.ts.Toast.text("二维码:"+result);
                    getWXOpenId(sessionStorage.getItem('openId')).then(res => {
                        openId = res.data.data
                        //window.ts.Toast.text("openid:"+openId)
                        //window.ts.Toast.text("data:"+openId+' , '+oldman.userId+' , '+result)
                        bindDevice(openId,oldman.userId,result).then(res => {
                            if (res.data.code == 200) {
                                window.ts.Toast.text("添加成功");
                                //刷新页面
                                //this.reload();
                                location.reload();
                            } else {
                                window.ts.Toast.text(res.data.message)
                            }
                        })
                    });
                },
                //error:function(res){
                //    console.log(res)
                //}
            })

            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            //window.ts.Toast.text("are you ok")
        });

        //wx.error(function (res) {
        //    window.ts.Toast.text("no ok")
        //    layer.msg(res);
        //    console.log(res);
        //});
    }
</script>

<style scoped>

</style>